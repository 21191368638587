<template>
  <span>
    <Component class="w-full h-full" :is="props.name" />
  </span>
</template>

<script setup>
// import { defineAsyncComponent } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
});
// const componentLoader = defineAsyncComponent(
//   () => import(`@/components/icons/${props.name}.vue`),
// )
</script>
